import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { sortBy } from "lodash";
import { useAlert } from "../components/hooks/useAlert";
import useIsFeatureEnabled from "../components/hooks/useIsFeatureEnabled";
import useQuery from "../components/hooks/useQuery";
import FeatureConstants from "../constants/FeatureConstants";
import AuthContext from "../contexts/AuthContext";
import WBCYearsContext from "../contexts/WBCYearsContext";
import WBCYearApi from "../httpClients/WBCYearApi";

const LOCAL_STORAGE_WBC_YEARS_KEY = "wbcYears";

const getYearQueryParam = query => parseInt(query.get("year"), 10) || undefined;

const isCurrent = ({ current }) => current;
const findCurrentWbcYear = wbcYears => wbcYears.find(isCurrent) || null;

const indexByYear = wbcYears =>
  wbcYears.reduce(
    (acc, wbcYear) => ({
      ...acc,
      [wbcYear.year]: wbcYear
    }),
    {}
  );

const extendWithOption = wbcYear => ({
  ...wbcYear,
  label: `${wbcYear.year}`,
  value: wbcYear.year
});

const WBCYearsContextProvider = ({ children }) => {
  const {
    state: { loggedIn, isBOC }
  } = useContext(AuthContext);
  const [wbcYears, setWbcYears] = useState([]);
  const [currentWbcYear, setCurrentWbcYear] = useState(null);
  const [selectedWbcYear, setSelectedWbcYear] = useState(null);
  const query = useQuery();
  const yearQueryParam = getYearQueryParam(query);
  const isChangeYearFeatureEnabled = useIsFeatureEnabled(FeatureConstants.CHANGE_YEAR);
  const wbcYearsByYear = useMemo(() => indexByYear(wbcYears), [wbcYears]);
  const selectedYear = selectedWbcYear && selectedWbcYear.year;
  const currentYear = currentWbcYear && currentWbcYear.year;
  const isCurrentYearSelected = selectedYear && selectedYear === currentYear;
  const history = useHistory();
  const { pathname } = useLocation();
  const alwaysCurrentYearPathnames = useMemo(() => {
    if (isBOC) {
      return new Set(["/settings", "/users"]);
    } else {
      return new Set(["/clubcomm", "/eligibility", "/settings", "/users"]);
    }
  }, [isBOC]);

  // callbacks
  const showAlert = useAlert();
  const showLoadingError = useCallback(() => showAlert("An unexpected server error has occurred.", "danger"), [
    showAlert
  ]);

  const updateWbcYears = useCallback(wbcYears => {
    setWbcYears(wbcYears);
    setCurrentWbcYear(findCurrentWbcYear(wbcYears));
  }, []);

  const changeSelectedYear = useCallback(
    year => {
      if (query.get("year") !== `${year}`) {
        query.set("year", year);
        history.replace(
          {
            search: query.toString()
          },
          history.location.state
        );
      }
    },
    [history, query]
  );

  const loadWbcYears = useCallback(() => {
    const cachedYearsJSON = localStorage.getItem(LOCAL_STORAGE_WBC_YEARS_KEY);
    if (cachedYearsJSON) {
      updateWbcYears(JSON.parse(cachedYearsJSON));
    }

    if (loggedIn) {
      WBCYearApi.getYears()
        .then(wbcYears =>
          sortBy(
            wbcYears.filter(wbcYear => isChangeYearFeatureEnabled || isCurrent(wbcYear)).map(extendWithOption),
            ({ year }) => -year
          )
        )
        .then(sortedWbcYears => {
          const yearsJSON = JSON.stringify(sortedWbcYears);

          if (yearsJSON !== cachedYearsJSON) {
            localStorage.setItem(LOCAL_STORAGE_WBC_YEARS_KEY, yearsJSON);
            updateWbcYears(sortedWbcYears);
          }
        }, showLoadingError);
    }
  }, [isChangeYearFeatureEnabled, loggedIn, showLoadingError, updateWbcYears]);

  // effects
  useEffect(loadWbcYears, [loadWbcYears]);

  useEffect(() => {
    if (yearQueryParam) {
      setSelectedWbcYear(wbcYearsByYear[yearQueryParam]);
    } else if (!alwaysCurrentYearPathnames.has(pathname) && (selectedYear || currentYear)) {
      changeSelectedYear(selectedYear || currentYear);
    } else if (alwaysCurrentYearPathnames.has(pathname) && selectedYear !== currentYear) {
      setSelectedWbcYear(currentYear);
    }
  }, [
    changeSelectedYear,
    currentYear,
    pathname,
    selectedYear,
    wbcYearsByYear,
    alwaysCurrentYearPathnames,
    yearQueryParam
  ]);

  // render
  return (
    <WBCYearsContext.Provider
      value={{
        wbcYears,
        currentWbcYear,
        reloadWbcYears: loadWbcYears,
        selectedWbcYear,
        selectedYear,
        changeSelectedYear,
        currentYear,
        isCurrentYearSelected
      }}
    >
      {children}
    </WBCYearsContext.Provider>
  );
};

WBCYearsContextProvider.propTypes = {
  children: PropTypes.node
};

export default WBCYearsContextProvider;
