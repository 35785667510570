import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { DateRangePickerWithLabel } from "best-common-react";
import WBCModal from "../../elements/WBCModal";
import { AddModalBodyContent, AddModalFooterContent } from "./AddFederationTeamModal";
import { WBCPrimaryButton } from "../../elements/WBCPrimaryButton";
import FederationTeamCard from "./FederationTeamCard";
import FlagHolder from "../../elements/WBCFlagHolder";
import { ALL_TOURNAMENT_TYPES, QUALIFYING } from "../../../constants/TournamentTypeConstants";
import AuthContext from "../../../contexts/AuthContext";
import CountryContext from "../../../contexts/CountryContext";
import { formatDate } from "../../../utils/DateUtils";
import DesktopTabletView from "../../temp/DesktopTabletView";
import MobileView from "../../temp/MobileView";
import WBCAdminAccess from "../../protected/WBCAdminAccess";

const FLAG_URL = window.MLBBest.envVariables.REACT_APP_FLAG_URL;

const Title = styled.h2`
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 300;
  color: ${props => props.theme["dark-grey"]};
`;

const StyledCol = styled.div.attrs(() => ({
  className: "col-xs col-md-4 col-lg-3",
  key: props => props.key
}))`
  &&& {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

// TODO: get rid of this and use disabled DateRangePickerWithLabel
// after next BCR release https://github.mlbam.net/Baseball-Enterprise-Common/best-common-react/pull/382
const datesLabel = "Dates";
const Label = styled.label`
  color: #000000;
  font-weight: bold;
  font-size: 0.875rem;
  margin-bottom: 0;
`;
const ReadOnlyDateRange = ({ startDate, endDate }) => (
  <div style={{ textAlign: "left" }}>
    <Label>{datesLabel}</Label>
    <div>
      {formatDate(startDate)} - {formatDate(endDate)}
    </div>
  </div>
);

ReadOnlyDateRange.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired
};

const sanitizeDate = date => (typeof date === "string" ? date : date.format("YYYY-MM-DD"));

const FederationTeamsCard = ({
  title,
  teamList,
  moveTeamOnClick,
  tournamentType,
  addTeams,
  removeTeam,
  dateRange,
  onDateRangeChange: onDateRangeChangeProp
}) => {
  // hooks
  const {
    state: { isBOCadmin }
  } = useContext(AuthContext);
  const countryContext = useContext(CountryContext);
  const [addTeamModalStatus, toggleAddModal] = useState(false);
  const [newTeams, setNewTeams] = useState([]);

  // variables
  const { countryIdToName, countryIdToCode } = countryContext.state;

  // effect(s)
  // TODO: this should be removed
  useEffect(() => {}, [countryContext.state.countries]);

  const onDateRangeChange = useCallback(
    ({ start, end }) =>
      onDateRangeChangeProp({
        tournamentType,
        dateRange: {
          startDate: sanitizeDate(start),
          endDate: sanitizeDate(end)
        }
      }),
    [tournamentType, onDateRangeChangeProp]
  );

  return (
    <div>
      <WBCModal
        isOpen={addTeamModalStatus}
        toggleModal={toggleAddModal}
        headerText="Add Federation Teams"
        onClose={() => setNewTeams([])}
      >
        <AddModalBodyContent
          setNewTeams={setNewTeams}
          onSubmit={() => {
            addTeams(newTeams);
            toggleAddModal(false);
            setNewTeams([]);
          }}
        />
        <AddModalFooterContent
          formStatus={newTeams.length === 0}
          onSave={() => {
            addTeams(newTeams);
            toggleAddModal(false);
            setNewTeams([]);
          }}
          onCancel={() => {
            toggleAddModal(false);
            setNewTeams([]);
          }}
        />
      </WBCModal>

      <div>
        <div className="d-flex justify-content-between m-1">
          <Title>{`${title} (${teamList.length})`}</Title>
          <MobileView maxWidth={1023}>
            <WBCAdminAccess>
              <WBCPrimaryButton
                width={59}
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  toggleAddModal(true);
                }}
              >
                Add
              </WBCPrimaryButton>
            </WBCAdminAccess>
          </MobileView>
          <DesktopTabletView minWidth={1024}>
            <WBCAdminAccess>
              <WBCPrimaryButton
                width={193}
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  toggleAddModal(true);
                }}
              >
                Add Federation Teams
              </WBCPrimaryButton>
            </WBCAdminAccess>
          </DesktopTabletView>
        </div>
        {dateRange && (
          <div className="m-1">
            {isBOCadmin ? (
              <DateRangePickerWithLabel
                value={{ start: dateRange.startDate, end: dateRange.endDate }}
                onChange={onDateRangeChange}
                label={datesLabel}
                required
              />
            ) : (
              <ReadOnlyDateRange startDate={dateRange.startDate} endDate={dateRange.endDate} />
            )}
          </div>
        )}
        {teamList && teamList.length > 0 ? (
          <div className="container-fluid">
            <div className="row">
              {teamList.map(team => {
                if (!!countryIdToName[team.countryId]) {
                  return (
                    <StyledCol key={countryIdToName[team.countryId]}>
                      {tournamentType === QUALIFYING ? (
                        <FederationTeamCard
                          team={team}
                          moveTeamOnClick={moveTeamOnClick}
                          onRemove={removeTeam}
                          type="qualifiers"
                        >
                          <FlagHolder
                            height={30}
                            width={40}
                            src={`${FLAG_URL}/${countryIdToCode[team.countryId].toLowerCase()}.svg`}
                          />
                        </FederationTeamCard>
                      ) : (
                        <FederationTeamCard team={team} onRemove={removeTeam} type="tournament">
                          <FlagHolder
                            height={30}
                            width={40}
                            src={`${FLAG_URL}/${countryIdToCode[team.countryId].toLowerCase()}.svg`}
                          />
                        </FederationTeamCard>
                      )}
                    </StyledCol>
                  );
                }
                return "";
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

FederationTeamsCard.propTypes = {
  title: PropTypes.string,
  teamList: PropTypes.array,
  moveTeamOnClick: PropTypes.func,
  removeTeam: PropTypes.func,
  addTeams: PropTypes.func,
  tournamentType: PropTypes.oneOf(ALL_TOURNAMENT_TYPES).isRequired,
  dateRange: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired
  }),
  onDateRangeChange: PropTypes.func.isRequired
};

FederationTeamsCard.defaultProps = {
  teamList: []
};

export default FederationTeamsCard;
