import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormInput } from "best-common-react";
import WBCCollapse from "../elements/WBCCollapse";
import WBCHorizontalFieldWrapper from "../elements/WBCHorizontalFieldWrapper";
import WBCHorizontalFieldInput from "../elements/WBCHorizontalFieldInput";
import WBCAccessControl from "../protected/WBCAccessControl";
import DisabledInputStyle from "../elements/DisabledInputStyle";
import WBCRadioButton from "../elements/WBCRadioButton";
import FeatureConstants from "../../constants/FeatureConstants";
import FeatureToggler from "../elements/FeatureToggler";

const GeneralRosterContainer = styled.div`
  padding: 24px;
`;

const RadioButtonContainer = styled.div.attrs(() => ({
  className: "d-flex align-items-center"
}))`
  &&& {
    font-size: 14px;
  }
`;

const GeneralRosterSettings = ({ settings, changeFn }) => {
  return (
    <GeneralRosterContainer>
      <WBCCollapse title="General Roster Settings">
        <WBCAccessControl
          input={isDisabled => (
            <div className={"row"}>
              <div className={"col-lg-6"}>
                <WBCHorizontalFieldWrapper label="Interest List Max" required={!isDisabled}>
                  <WBCHorizontalFieldInput label="Players">
                    <FormInput
                      type="number"
                      name="interestListMax"
                      value={settings.interestListMax}
                      editable
                      isRequired={!isDisabled}
                      onChangeFn={value => changeFn("ROSTER_SETTINGS", { ...settings, interestListMax: value })}
                      isDisabled={isDisabled}
                      inputStyle={DisabledInputStyle}
                    />
                  </WBCHorizontalFieldInput>
                </WBCHorizontalFieldWrapper>
              </div>
              <div className={"col-lg-6"}>
                <FeatureToggler feature={FeatureConstants.MAIN_TOURNAMENT}>
                  <WBCHorizontalFieldWrapper
                    label="Add Available Players to"
                    required={!isDisabled}
                    width={240}
                  ></WBCHorizontalFieldWrapper>
                  <RadioButtonContainer>
                    <div className="mr-3">
                      <WBCRadioButton
                        checked={settings.tournamentTypeId === 1}
                        onClick={() => {
                          changeFn("ROSTER_SETTINGS", { ...settings, tournamentTypeId: 1 });
                        }}
                        label="Qualifying Roster"
                        bold={false}
                        isDisabled={isDisabled}
                      />
                    </div>
                    <div className="mr-3">
                      <WBCRadioButton
                        checked={settings.tournamentTypeId === 2}
                        onClick={() => {
                          changeFn("ROSTER_SETTINGS", { ...settings, tournamentTypeId: 2 });
                        }}
                        label="Main Tournament Roster"
                        bold={false}
                        isDisabled={isDisabled}
                      />
                    </div>
                  </RadioButtonContainer>
                </FeatureToggler>
              </div>
            </div>
          )}
        />
      </WBCCollapse>
    </GeneralRosterContainer>
  );
};

GeneralRosterSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  changeFn: PropTypes.func.isRequired
};

export default GeneralRosterSettings;
