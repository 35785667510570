import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import WBCCollapse from "../elements/WBCCollapse";
import WBCAccessControl from "../protected/WBCAccessControl";
import ProvisionalSettings from "./tournament/ProvisionalSettings";
import FinalSettings from "./tournament/FinalSettings";

const TournamentSettingsContainer = styled.div`
  padding: 24px;
  overflow: auto;
  text-align: left;
`;

const TournamentSettings = ({ title, settings, changeFn, changeCode }) => (
  <TournamentSettingsContainer>
    <WBCCollapse title={title}>
      <WBCAccessControl
        noDiv
        input={isDisabled => (
          <div className="row">
            <ProvisionalSettings
              settings={settings}
              isDisabled={isDisabled}
              changeFn={changeFn}
              changeCode={changeCode}
            />
            <FinalSettings settings={settings} isDisabled={isDisabled} changeFn={changeFn} changeCode={changeCode} />
          </div>
        )}
      />
    </WBCCollapse>
  </TournamentSettingsContainer>
);

TournamentSettings.propTypes = {
  title: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  changeFn: PropTypes.func.isRequired,
  changeCode: PropTypes.string.isRequired
};

export default TournamentSettings;
