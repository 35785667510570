import styled from "styled-components";
import Theme from "../../Theme";
import React from "react";
import PropTypes from "prop-types";
import { DisabledButton } from "./WBCPrimaryButton";

const PrimaryOutlineButton = styled(DisabledButton).attrs(() => ({
  className: "btn btn-outline-primary",
  name: "primary-outline-button"
}))``;

const WBCPrimaryOutlinedButtonStyled = styled(PrimaryOutlineButton).attrs(() => ({
  className: "justify-content-center d-flex",
  type: "submit"
}))`
  &&& {
    width: ${props => `${props.width}px`};
    height: ${props => `${props.height}px`};
    border-radius: 4px;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: bold;
    background-color: #ffffff;
    border-color: ${Theme.wbcBlue};
    color: ${Theme.wbcBlue};

    /* firefox only */
    @supports (-moz-appearance: none) {
      line-height: ${props => `${props.height - 2}px`};
    }
  }
`;

const WBCPrimaryOutlinedButton = ({ styles, children, ...props }) => (
  <WBCPrimaryOutlinedButtonStyled style={styles} {...props}>
    {children}
  </WBCPrimaryOutlinedButtonStyled>
);

WBCPrimaryOutlinedButton.propTypes = {
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

WBCPrimaryOutlinedButton.defaultProps = {
  width: 90,
  height: 26
};

export default WBCPrimaryOutlinedButton;
