import React, { useReducer, useMemo, useState, useEffect } from "react";
import { AlertConstants } from "best-common-react";
import PropTypes from "prop-types";
import ChronicConditionsContext from "../contexts/ChronicConditionsContext";
import ChronicConditionsApi from "../httpClients/ChronicConditionsApi";
import { useAlert } from "../components/hooks/useAlert";

const DEFAULT_INSURANCE_STATUS_FILTER = { label: "All Insurance Statuses", value: null };

const INITIAL_STATE = {
  playerConditions: [],
  insuranceStatusFilter: DEFAULT_INSURANCE_STATUS_FILTER,
  insuranceStatuses: [],
  insuranceActions: [],
  selectedPlayerIds: [],
  insuranceStatusSelected: null,
  nameSearchFilter: null,
  sortFilters: {
    col: "lastName",
    dir: "ASC"
  }
};

const ChronicConditionsContextProvider = ({ children }) => {
  const [playerConditions, setPlayerConditions] = useState(INITIAL_STATE.playerConditions);
  const [insuranceStatusFilter, setInsuranceStatusFilter] = useState(INITIAL_STATE.insuranceStatusFilter);
  const [insuranceStatuses, setInsuranceStatuses] = useState(INITIAL_STATE.insuranceStatuses);
  const [selectedPlayerIds, setSelectedPlayerIds] = useState(INITIAL_STATE.selectedPlayerIds);
  const [insuranceActions, setInsuranceActions] = useState(INITIAL_STATE.insuranceActions);
  const [insuranceStatusSelected, setInsuranceStatusSelected] = useState(INITIAL_STATE.insuranceStatusSelected);
  const [nameSearchFilter, setNameSearchFilter] = useState(INITIAL_STATE.nameSearchFilter);
  const [sortFilters, setSortFilters] = useState(INITIAL_STATE.sortFilters);
  const [filteredPlayerConditions, setFilteredPlayerConditions] = useState([]);
  const [actionSelected, setActionSelected] = useState(null);
  const [updatePlayers, setUpdatePlayers] = useState(false);
  const showAlert = useAlert();

  useEffect(() => {
    setFilteredPlayerConditions(
      playerConditions
        .filter(
          player =>
            (!nameSearchFilter ||
              player?.firstName?.toLowerCase().includes(nameSearchFilter?.toLowerCase()) ||
              player?.lastName?.toLowerCase().includes(nameSearchFilter?.toLowerCase())) &&
            (insuranceStatusFilter.value === null || player.insuranceStatus === insuranceStatusFilter.label)
        )
        .map(player => ({ ...player, isChecked: false, disabled: false }))
    );
    console.log(insuranceStatusFilter);
  }, [playerConditions, insuranceStatusFilter, nameSearchFilter]);

  useEffect(() => {
    if (!!insuranceStatusSelected) {
      const updatedActions = [...insuranceActions].map(action => {
        action.isActive = actionCanBePerformed(action, insuranceStatusSelected?.name);
        return action;
      });
      setInsuranceActions(updatedActions);
    } else {
      setInsuranceActions(
        [...insuranceActions].map(action => {
          action.isActive = false;
          return action;
        })
      );
    }
  }, [insuranceStatusSelected]);

  useEffect(() => {
    if (!!actionSelected && actionSelected.isActive) {
      const promises = selectedPlayerIds.map(selectedPlayerId => {
        ChronicConditionsApi.updateChronicConditionsStatus(selectedPlayerId, actionSelected.value);
      });
      Promise.all(promises)
        .then(() => {
          showAlert("Success", AlertConstants.TYPES.SUCCESS);
          // updating too fast, need to add a delay
          new Promise(resolve => setTimeout(resolve, 100)).then(() => {
            setUpdatePlayers(!updatePlayers);
          });
        })
        .catch(() => {
          showAlert("Error while updating player status", AlertConstants.TYPES.DANGER);
        });
      setSelectedPlayerIds([]);
      setInsuranceStatusSelected(null);
    }
  }, [actionSelected]);

  const actionCanBePerformed = (action, status) => {
    if (status === null) {
      return false;
    } else if (status === "Insurance Review") {
      switch (action.action) {
        case "Approve":
        case "Deny":
        case "Medical Review":
        case "Remove From List":
          return true;
        default:
          return false;
      }
    } else if (status === "Medical Review") {
      switch (action.action) {
        case "Approve":
        case "Deny":
          return true;
        default:
          return false;
      }
    }
    if (status === "Approved" || status === "Denied") {
      switch (action.action) {
        case "Remove From List":
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "sortPlayers":
        setPlayerConditions(action.playerConditions);
        return {
          ...state,
          sortFilters: {
            ...state.sortFilters,
            col: action.col,
            dir: action.dir
          },
          playerConditions: [...action.playerConditions]
        };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <ChronicConditionsContext.Provider
      value={{
        state,
        dispatch,
        sortFilters,
        playerConditions,
        setPlayerConditions,
        insuranceStatusFilter,
        setInsuranceStatusFilter,
        insuranceStatuses,
        setInsuranceStatuses,
        nameSearchFilter,
        setNameSearchFilter,
        filteredPlayerConditions,
        selectedPlayerIds,
        setSelectedPlayerIds,
        insuranceActions,
        setInsuranceActions,
        insuranceStatusSelected,
        setInsuranceStatusSelected,
        filteredPlayerConditions,
        setFilteredPlayerConditions,
        setActionSelected,
        updatePlayers
      }}
    >
      {children}
    </ChronicConditionsContext.Provider>
  );
};

ChronicConditionsContextProvider.propTypes = {
  children: PropTypes.object
};

export default ChronicConditionsContextProvider;
