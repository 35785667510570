import React, { useContext, useState } from "react";
import styled from "styled-components";
import WBCCheckbox from "../../elements/WBCCheckbox";
import WBCDataTable from "../../data-table/WBCDataTable";
import PropTypes from "prop-types";
import PlayerLinkFormatter from "../../table/PlayerLinkFormatter";
import ChronicConditionsContext from "../../../contexts/ChronicConditionsContext";
import TableSortFilterContext from "../../../contexts/TableSortFilterContext";
import { sortPlayers } from "../../roster/RosterSortHelper";
import AuthContext from "../../../contexts/AuthContext";
import RosterStatusFormatter from "../mlb-player-summary/MLBPlayerRosterStatusFormatter";

const CheckboxContainer = styled.div`
  cursor: pointer;
`;

const ChronicConditionsTable = () => {
  // context variables
  const chronicConditionContext = useContext(ChronicConditionsContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const {
    filteredPlayerConditions,
    sortFilters,
    playerConditions,
    insuranceStatusSelected,
    setFilteredPlayerConditions,
    setSelectedPlayerIds,
    selectedPlayerIds,
    setInsuranceStatusSelected,
    insuranceActions
  } = chronicConditionContext;
  const { isBOCadmin } = useContext(AuthContext).state;

  const OrgClubFormatter = ({ value, row }) => {
    return row.org.toUpperCase() === "BOC" ? "" : value;
  };
  const ChronicConditionsSelectCheckbox = ({ ...props }) => {
    return isBOCadmin ? (
      <CheckboxContainer>
        <WBCCheckbox checked={props.row.isChecked} isDisabled={props.row.isChecked} />
      </CheckboxContainer>
    ) : null;
  };

  ChronicConditionsSelectCheckbox.propTypes = {
    row: PropTypes.any,
    isChecked: PropTypes.any,
    value: PropTypes.any,
    rowIdx: PropTypes.any,
    idx: PropTypes.any
  };

  const onRowsSelected = row => {
    const newInsuranceStatus = insuranceActions.find(is => is.name === row?.insuranceStatus);
    if (insuranceStatusSelected == null && !!newInsuranceStatus) {
      setInsuranceStatusSelected({ ...newInsuranceStatus });
    }

    const updatedPlayerConditions = filteredPlayerConditions.map(player => {
      if (row.profileId === player.profileId) {
        player.isChecked = true;
      }
      if (player.insuranceStatus !== newInsuranceStatus?.name) {
        player.disabled = true;
      }
      return player;
    });
    setFilteredPlayerConditions([...updatedPlayerConditions]);
    setSelectedPlayerIds([...updatedPlayerConditions.filter(i => i.isChecked).map(i => i.profileId)]);
  };

  const onRowsDeselected = row => {
    const updatedPlayerConditions = filteredPlayerConditions.map(player => {
      if (row.profileId === player.profileId) {
        player.isChecked = false;
      }
      return player;
    });
    const newSelectedPlayerIds = updatedPlayerConditions.filter(i => i.isChecked).map(i => i.profileId);
    setFilteredPlayerConditions([...updatedPlayerConditions]);
    setSelectedPlayerIds([...newSelectedPlayerIds]);
    if (newSelectedPlayerIds.length === 0) {
      setInsuranceStatusSelected(null);
      setFilteredPlayerConditions([...filteredPlayerConditions.map(player => ({ ...player, disabled: false }))]);
    }
  };

  const columns = [
    { name: "", key: "id", width: 50, formatter: ChronicConditionsSelectCheckbox },
    { name: "Insurance Status", key: "insuranceStatus", width: 180, sortable: true },
    { name: "Last", key: "lastName", width: 180, sortable: true, formatter: PlayerLinkFormatter },
    { name: "First", key: "firstName", width: 180, sortable: true, formatter: PlayerLinkFormatter },
    { name: "Pos", key: "position", sortable: true, width: 75 },
    { name: "Org", key: "org", width: 75, sortable: true, formatter: OrgClubFormatter },
    { name: "Club", key: "club", width: 180, sortable: true, formatter: OrgClubFormatter },
    { name: "Roster Status", key: "mlbRosterStatus", sortable: true, width: 150, formatter: RosterStatusFormatter },
    { name: "Federation", key: "federation", sortable: true, width: 600 }
  ];

  return (
    <WBCDataTable
      useBCR
      columns={columns}
      rowSelection={{
        showCheckbox: false,
        selectBy: {
          isSelectedKey: "isChecked"
        }
      }}
      enableRowSelect={true}
      onRowClick={(idx, row, column) => {
        if (column?.idx == 0 && isBOCadmin) {
          if (row.disabled == false) {
            if (selectedPlayerIds?.includes(row?.profileId)) {
              onRowsDeselected(row);
            } else {
              onRowsSelected(row);
            }
          }
        }
      }}
      rowDisabledKey="disabled"
      headerRowHeight={40}
      minHeight={400}
      rowHeight={45}
      data={filteredPlayerConditions}
      sortFunc={(col, dir) => {
        chronicConditionContext.dispatch({
          col,
          dir,
          type: "sortPlayers",
          playerConditions: sortPlayers(col, dir, [...playerConditions], false)
        });
        tableSortFilterContext.dispatch({
          type: "setSortFilters",
          sortFilters: { col, dir }
        });
      }}
      sortColumn={sortFilters.col}
      sortDirection={sortFilters.dir}
    />
  );
};

export default ChronicConditionsTable;
