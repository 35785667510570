import React, { useContext } from "react";
import AddInterestFormatter from "./AddInterestFormatter";
import ProspectivePlayerContext from "../../contexts/ProspectivePlayerContext";
import PlayerLinkFormatter from "../table/PlayerLinkFormatter";
import BirthCountryFormatter from "../table/BirthCountryFormatter";
import PositionFormatter from "../table/PositionFormatter";
import ClubFormatter from "../table/ClubFormatter";
import OrgFormatter from "../table/OrgFormatter";
import PropTypes from "prop-types";
import BirthDateFormatter from "./BirthDateFormatter";
import CheckMarkFormatter from "../elements/CheckMarkFormatter";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import { sortPlayers } from "../roster/RosterSortHelper";
import TableSortFilterContext from "../../contexts/TableSortFilterContext";
import WBCDataTable from "../data-table/WBCDataTable";
import RosterStatusFormatter from "../mlb-players/mlb-player-summary/MLBPlayerRosterStatusFormatter";
import AuthContext from "../../contexts/AuthContext";

export const BatsThrowsFormatter = value => {
  const displayedValue =
    value && value.value && value.dependentValues.throwsString
      ? `${value.value}/${value.dependentValues.throwsString}`
      : "";
  return <div>{displayedValue}</div>;
};

BatsThrowsFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

const IlDaysFormatter = ({ value }) => {
  return <div>{value ? value : null}</div>;
};

IlDaysFormatter.propTypes = {
  value: PropTypes.number
};

const columns = [
  {
    key: "addInterest",
    name: " ",
    sortable: false,
    width: 31,
    formatter: AddInterestFormatter,
    getRowMetaData: row => row
  },
  {
    key: "lastName",
    name: "Last",
    sortable: true,
    width: 120,
    formatter: PlayerLinkFormatter,
    getRowMetaData: row => row
  },
  {
    key: "firstName",
    name: "First",
    sortable: true,
    width: 120,
    formatter: PlayerLinkFormatter,
    getRowMetaData: row => row
  },
  {
    key: "positionId",
    name: "Pos",
    sortable: true,
    width: 50,
    formatter: PositionFormatter,
    getRowMetaData: row => row
  },
  {
    key: "bats",
    name: "B/T",
    sortable: true,
    width: 50,
    formatter: BatsThrowsFormatter,
    getRowMetaData: row => row
  },
  {
    key: "birthDate",
    name: "DOB",
    sortable: true,
    width: 110,
    formatter: BirthDateFormatter,
    getRowMetaData: row => row
  },
  {
    key: "birthCountryId",
    name: "Birth Country",
    sortable: true,
    width: 150,
    formatter: BirthCountryFormatter,
    getRowMetaData: row => row
  },
  {
    key: "pastWbcFlag",
    name: "Past WBC",
    sortable: true,
    width: 100,
    formatter: CheckMarkFormatter
  },
  {
    key: "ebisId",
    name: "EBIS ID",
    sortable: true,
    width: 90,
    getRowMetaData: row => row
  },
  {
    key: "orgId",
    name: "Org",
    sortable: true,
    width: 65,
    formatter: OrgFormatter,
    getRowMetaData: row => row
  },
  {
    key: "clubId",
    name: "Club",
    sortable: true,
    width: 250,
    formatter: ClubFormatter,
    getRowMetaData: row => row
  },
  {
    key: "fortyManStatus",
    name: "40-Man",
    sortable: true,
    width: 85,
    formatter: CheckMarkFormatter,
    getRowMetaData: row => row
  },
  {
    key: "ilFlag",
    name: "IL",
    sortable: true,
    width: 60,
    formatter: CheckMarkFormatter,
    getRowMetaData: row => row
  },
  {
    key: "ebisMlbRosterStatus",
    name: "MLB Roster Status",
    sortable: true,
    width: 200,
    formatter: RosterStatusFormatter
  }
];

const fedColumns = columns.filter(({ key }) => key !== "ebisId");

const BodyContent = ({ message }) => <div>{message}</div>;
const FooterContent = ({ onClose }) => (
  <WBCPrimaryButton
    onClick={e => {
      e.stopPropagation();
      onClose();
    }}
  >
    Continue
  </WBCPrimaryButton>
);

const ProspectivePlayersTable = () => {
  // context(s) and state
  const prospectivePlayerContext = useContext(ProspectivePlayerContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const authContext = useContext(AuthContext);
  const { isBOC, isTeamCoordinator } = authContext.state;

  // variable(s)
  const { displayedPlayers, prospectivePlayers } = prospectivePlayerContext.state;

  return (
    <div className="row">
      <div className="col-12 full-page-table">
        <WBCDataTable
          useBCR
          id="prospective-players-table"
          enableRowSelect={null}
          rowScrollTimeout={null}
          data={displayedPlayers}
          columns={isBOC || isTeamCoordinator ? columns : fedColumns}
          minColumnWidth={150}
          sortFunc={(col, dir) => {
            prospectivePlayerContext.dispatch({
              type: "setProspectivePlayers",
              prospectivePlayers: sortPlayers(col, dir, prospectivePlayers)
            });
            tableSortFilterContext.dispatch({
              type: "setSortFilters",
              sortFilters: { col, dir }
            });
          }}
          sortColumn={tableSortFilterContext.state.sortFilters.col}
          sortDirection={tableSortFilterContext.state.sortFilters.dir}
        />
      </div>
    </div>
  );
};

ProspectivePlayersTable.propTypes = {};

BodyContent.propTypes = {
  message: PropTypes.string
};

BodyContent.defaultProps = {
  message: ""
};

FooterContent.propTypes = {
  onClose: PropTypes.func
};

FooterContent.defaultProps = {
  onClose: () => {}
};

BodyContent.displayName = "BodyContent";
FooterContent.displayName = "FooterContent";

export default ProspectivePlayersTable;
