import { Icon } from "best-common-react";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import WBCAdminAccess from "../../protected/WBCAdminAccess";
import CountryContext from "../../../contexts/CountryContext";

const FederationTeam = styled.div.attrs(() => ({
  className: "d-flex justify-content-between m-1"
}))`
  &&& {
    height: 50px;
    border: solid 1px #d2d2d2;
    background-color: #ffffff;
    color: ${props => props.theme.wbcBlue};
  }
`;

const FlagContainer = styled.div.attrs(() => ({
  className: "d-flex align-items-center p-2 text-truncate"
}))`
  &&& {
  }
`;

const NameContainer = styled.div.attrs(() => ({
  className: "d-inline-block text-truncate"
}))`
  font-size: 16px;
  text-align: left;
`;

const NameContainerForModal = styled.div.attrs(() => ({
  className: "d-inline-block text-truncate"
}))`
  &&& {
    width: 200px;
    font-size: 16px;
  }
`;

const IconContainer = styled.div.attrs(() => ({
  className: "d-flex justify-content-between align-items-center pr-3"
}))`
  min-width: 55px;
`;

const DefaultStyle = {
  width: "312px"
};

const FederationTeamCard = ({ team, children, moveTeamOnClick, onRemove, type, styles }) => {
  const countryContext = useContext(CountryContext);
  const CompStyle = { ...DefaultStyle, ...styles };

  switch (type) {
    case "qualifiers":
      return (
        <div>
          <FederationTeam styles={CompStyle}>
            <FlagContainer>
              {children}
              <NameContainer>{countryContext.state.countryIdToName[team.countryId]}</NameContainer>
            </FlagContainer>
            <WBCAdminAccess>
              <IconContainer>
                <Icon
                  iconName={"fa-level-down-alt"}
                  onClick={() => {
                    moveTeamOnClick(team);
                  }}
                />
                <Icon
                  iconName={"fa-trash-alt"}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    onRemove(team, type);
                  }}
                />
              </IconContainer>
            </WBCAdminAccess>
          </FederationTeam>
        </div>
      );
    case "tournament":
      return (
        <div>
          <FederationTeam styles={CompStyle}>
            <FlagContainer>
              {children}
              <NameContainer>{countryContext.state.countryIdToName[team.countryId]}</NameContainer>
            </FlagContainer>
            <WBCAdminAccess>
              <Icon
                style={{ marginTop: "16px", marginRight: "10px" }}
                iconName={"fa-trash-alt"}
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  onRemove(team, type);
                }}
              />
            </WBCAdminAccess>
          </FederationTeam>
        </div>
      );
    case "removal":
      return (
        <div>
          <FederationTeam styles={CompStyle}>
            <FlagContainer>
              {children}
              <NameContainerForModal>{countryContext.state.countryIdToName[team.countryId]}</NameContainerForModal>
            </FlagContainer>
          </FederationTeam>
        </div>
      );
    default:
      return;
  }
};

FederationTeamCard.propTypes = {
  team: PropTypes.object,
  children: PropTypes.object,
  moveTeamOnClick: PropTypes.func,
  onRemove: PropTypes.func,
  type: PropTypes.string,
  styles: PropTypes.object
};

export default FederationTeamCard;
